import PropTypes from 'prop-types';
// form
import { FormProvider as Form } from 'react-hook-form';

// ----------------------------------------------------------------------

FormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
};




export default function FormProvider({ children, onSubmit, methods }) {
  const handleKeyDown = (event) => {
    console.log('Enter key pressed');
    if (event.key === 'Enter') {
      event.preventDefault();
      console.log('Enter key pressed');
      // Lógica adicional en caso de presionar Enter
    }
  };
  return (
    <Form onKeyDown={handleKeyDown} {...methods}  >
      <form onSubmit={onSubmit} >{children}</form>
    </Form>
  );
}
