import axios from 'axios';

// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API
});



axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong'),
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response.status === 401 && !originalRequest._retry) {
      console.log("recarga tu pagina")
      // useSnackbar("Su sesion ha caducado, por favor recargue la página", {variant:"error"})
      // alert("Su sesión ha caducado, por favor recargue la página")
      // originalRequest._retry = true;

      // try {
      //   const refreshToken = localStorage.getItem('refreshToken');
      //   const response = await axios.post('/api/refresh-token', { refreshToken });
      //   const { token } = response.data;

      //   localStorage.setItem('token', token);

      //   // Retry the original request with the new token
      //   originalRequest.headers.Authorization = `Bearer ${token}`;
      //   return axios(originalRequest);
      // } catch (error) {
      //   // Handle refresh token error or redirect to login
      // }
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
