// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
 
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  reset: path(ROOTS_AUTH,'/password/setPassword')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
}; 

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
    sent: path(ROOTS_DASHBOARD, '/mail/sent')
  },
  decreto: {
    root: path(ROOTS_DASHBOARD, '/decreto'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  vehiculos: path(ROOTS_DASHBOARD, '/vehiculos'),
  bodega:{ 
    root: path(ROOTS_DASHBOARD, '/bodega'),
    form: path(ROOTS_DASHBOARD, '/bodega/form'),
    list: path(ROOTS_DASHBOARD, '/bodega/list'),

  } ,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  kanbanStats: path(ROOTS_DASHBOARD, '/kanbanStats'),

  funcionario: path(ROOTS_DASHBOARD, '/funcionario'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    subrogancia: path(ROOTS_DASHBOARD, '/user/subrogancia')
  },
  subrrogancias: { 
    root: path(ROOTS_DASHBOARD, '/subrogancias'),
    municipal: path(ROOTS_DASHBOARD, '/subrogancias/municipal'),
    salud: path(ROOTS_DASHBOARD, '/subrogancias/salud'),
    educacion: path(ROOTS_DASHBOARD, '/subrogancias/educacion'),
  },
  mantenedorsubrogancias: {
    root: path(ROOTS_DASHBOARD, '/mantenedorsubrogancias'),
    municipal: path(ROOTS_DASHBOARD, '/mantenedorsubrogancias/subrogancia'), 
    educacion: path(ROOTS_DASHBOARD, '/mantenedorsubrogancias/educacion'), 
    salud: path(ROOTS_DASHBOARD, '/mantenedorsubrogancias/salud'), 
  },
  mantenedordepartamentos: {
    root: path(ROOTS_DASHBOARD, '/mantenedordepartamentos'),
    municipal: path(ROOTS_DASHBOARD, '/mantenedordepartamentos/departamento'), 
    educacion: path(ROOTS_DASHBOARD, '/mantenedordepartamentos/educacion'), 
    salud: path(ROOTS_DASHBOARD, '/mantenedordepartamentos/salud'), 
  },
  mantenedorusuarios: {
    root: path(ROOTS_DASHBOARD, '/mantenedorusuario'),
    municipal: path(ROOTS_DASHBOARD, '/mantenedorusuario/usuario'), 
  },
  mantenedorvehiculo: {
    root: path(ROOTS_DASHBOARD, '/mantenedorvehiculo'),
    municipal: path(ROOTS_DASHBOARD, '/mantenedorvehiculo/vehiculo'), 
  },
  mantenedorimpresora: {
    root: path(ROOTS_DASHBOARD, '/mantenedorimpresora'),
    municipal: path(ROOTS_DASHBOARD, '/mantenedorimpresora/impresoras'), 
  },
  documental: {
    root: path(ROOTS_DASHBOARD, '/documental'),
    shop: path(ROOTS_DASHBOARD, '/documental/shop'),
    repositorio: path(ROOTS_DASHBOARD, '/documental/repositorio'),
    document: path(ROOTS_DASHBOARD, '/documental/document/:id'),
    memo: path(ROOTS_DASHBOARD, '/documental/memo'),
    memoRepo: path(ROOTS_DASHBOARD, '/documental/memoRepo'),
    decre: path(ROOTS_DASHBOARD, '/documental/decre'),
    decreRepo: path(ROOTS_DASHBOARD, '/documental/decre'),
    depto: path(ROOTS_DASHBOARD, '/documental/depto')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/document'),
    // shop: path(ROOTS_DASHBOARD, '/document/shop'),
    product: path(ROOTS_DASHBOARD, '/document/memo/:name'),
    document: path(ROOTS_DASHBOARD, '/document/document/:id'),
    // productById: path(ROOTS_DASHBOARD, '/document/memo/nike-air-force-1-ndestrukt'),
    // list: path(ROOTS_DASHBOARD, '/document/list'),
    newProduct: path(ROOTS_DASHBOARD, '/document/memo/new'),
    // editById: path(ROOTS_DASHBOARD, '/document/memo/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/document/decreto'),
    // invoice: path(ROOTS_DASHBOARD, '/document/invoice')
  },
  contratacion:{
    root: path(ROOTS_DASHBOARD, '/contracting'),
    new: path(ROOTS_DASHBOARD, '/contracting/enter/new'),
    list: path(ROOTS_DASHBOARD, '/contracting/list'),
  },
  contratacionAdmin:{
    root: path(ROOTS_DASHBOARD, '/contractingAdmin'),
    admin: path(ROOTS_DASHBOARD, '/contractingAdmin/enter/admin'),
    list: path(ROOTS_DASHBOARD, '/contractingAdmin/list'),
  },
  presupuesto:{
    root: path(ROOTS_DASHBOARD, '/presupuesto'),
    new: path(ROOTS_DASHBOARD, '/presupuesto/enter/new'),
    ingreso: path(ROOTS_DASHBOARD, '/presupuesto/enter/ingreso'),
    list: path(ROOTS_DASHBOARD, '/presupuesto/list'),
  },
  gas:{
    root: path(ROOTS_DASHBOARD, '/gas'),
    new: path(ROOTS_DASHBOARD, '/gas/enter/new'),
    list: path(ROOTS_DASHBOARD, '/gas/list'),
  },
  gasAdmin:{
    root: path(ROOTS_DASHBOARD, '/gasAdmin'),
    admin: path(ROOTS_DASHBOARD, '/gasAdmin/enter/admin'),
    list: path(ROOTS_DASHBOARD, '/gasAdmin/list'),
  },
  marcaciones:{
    root: path(ROOTS_DASHBOARD, '/marcaciones'), 
    propias: path(ROOTS_DASHBOARD, '/marcaciones/propias'),
  },
  asistencia:{
    root: path(ROOTS_DASHBOARD, '/asistencia'),
    new: path(ROOTS_DASHBOARD, '/asistencia/ingresar/nueva'),
    list: path(ROOTS_DASHBOARD, '/asistencia/list'),
  },
  tonerAdmin:{
    root: path(ROOTS_DASHBOARD, '/tonerAdmin'),
    admin: path(ROOTS_DASHBOARD, '/tonerAdmin/enter/admin'),
  },
  ticket:{
    root: path(ROOTS_DASHBOARD, '/ticket'),
    new: path(ROOTS_DASHBOARD, '/ticket/ingresar/nuevo'),
    list: path(ROOTS_DASHBOARD, '/asistencia/list'),
  },
  presupuestoAdmin:{
    root: path(ROOTS_DASHBOARD, '/presupuestoAdmin'),
    admin: path(ROOTS_DASHBOARD, '/presupuestoAdmin/enter/admin'),
    list: path(ROOTS_DASHBOARD, '/presupuestoAdmin/list'),
    programas:path(ROOTS_DASHBOARD, '/presupuestoAdmin/programas')
  },
  administrativos:{
    root: path(ROOTS_DASHBOARD, '/administrativos'),
    new: path(ROOTS_DASHBOARD, '/gas/enter/new'),
    list: path(ROOTS_DASHBOARD, '/administrativos/list'),
  },
  emergencias:{
    root: path(ROOTS_DASHBOARD, '/emergencias'),
    new: path(ROOTS_DASHBOARD, '/emergencias/ingresar/nuevo'),
    list: path(ROOTS_DASHBOARD, '/emergencias/list'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = '/dashboard/app';
