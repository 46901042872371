
import { useRef, useEffect, useState } from 'react';
// @mui

import { 
  // Typography, Card, 
  Dialog, DialogTitle, DialogContent, DialogContentText} from '@mui/material';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
// components

// import useCountdown from '../../../hooks/useCountdown';
import { LoginForm } from '../../../sections/auth/login';


 
// ----------------------------------------------------------------------




// ----------------------------------------------------------------------

export default function CargoUtilizado() {


  const { user, timeRes } = useAuth();
  // const openCompose = useRef(false);
  const [openCompose, setOpenCompose] = useState(false);
  
  

  // const timeLeft = timeRes 


  // const isMountedRef = useIsMountedRef();

  // const { enqueueSnackbar } = useSnackbar();

  // const [open, setOpen] = useState(null);
  // console.log("tiempo en segundos")
  // const cargos = [
  //   { group: 'Jefaturas', classify: user.departamento.jefaturas },
  //   { group: 'Subrrogancias', classify: user.departamento.subrrogancias_activas },
  //   { group: 'Usuario', classify: user.departamento.usuario_en}
  // ];

  // const handleOpen = (event) => {
  //   setOpen(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setOpen(null);
  // };

  // const handleLogout = async () => {
  //   try {
  //     await logout();
  //     navigate(PATH_AUTH.login, { replace: true });

  //     if (isMountedRef.current) {
  //       handleClose();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     enqueueSnackbar('Unable to logout!', { variant: 'error' });
  //   }
  // };
  // const handleDesactivar = async () => {
  //   console.log("hola")
  //   try {
  //       enqueueSnackbar('te has desactivado correctamente')
  //       dispatch (desactivarEstado({"estado":0})); 
  //       await logout();
  //       navigate(PATH_AUTH.login, { replace: true });
  //   //   navigate(PATH_AUTH.login, { replace: true });

  //   //   if (isMountedRef.current) {
  //   //     handleClose();
  //   //   }
  //    } catch (error) {
  //   //   console.error(error);
  //   //   enqueueSnackbar('Unable to logout!', { variant: 'error' });
  //   }
  // };
  useEffect(() => {
    let expiredTimer;
    window.clearTimeout(expiredTimer);
    const currentTime = Date.now();
    const timeLeft = timeRes * 1000 - currentTime;
    console.log("current time2", timeLeft)
    console.log(timeLeft);

    expiredTimer = window.setTimeout(() => {
      setOpenCompose(true)
    }, timeLeft);
  }, [timeRes]);

  console.log("rederes")

  // const countdown = useCountdown(timeRes);
  // console.log("minutos",countdown)
  // console.log("segundos",countdown.seconds)
  // console.log("estado",user.username)


  // if (countdown.days!=='00' && countdown.minutes==='00') 
  //   openCompose.current= true

  const onCloseCompose = () =>{
    setOpenCompose(false)
    console.log("Cerrandos")
  }
  return (
    <>
    {/* <Card sx={{ 
      padding: 2, 
      
      boxShadow: 3, 
      borderRadius: 2, 
      textAlign: 'center' 
    }}>
      <Typography >
        Tiempo restante de la sesión {countdown.minutes} minutos
      </Typography>
    </Card> */}
    <Dialog open={openCompose}>
      <DialogTitle>Tu sesión ha expirado.</DialogTitle>
      
      <DialogContent spacing={1} >
        <DialogContentText id="session-expired-description" mb={1}>
          Por favor, ingresa tu contraseña para continuar.
        </DialogContentText>

        <LoginForm interior onCloseCompose={()=>onCloseCompose()} usuario={user.username}/>
      </DialogContent>
    </Dialog>
  
      {/* <RHFSelect name="destino" label="Destino">
                  {cargos.map((category) => (
                    <optgroup key={category.group} label={category.group}>
                      {category.classify.map((classify) => (
                        <option key={classify} value={classify}>
                          {classify}
                        </option>
                      ))}
                    </optgroup>
                  ))}
      </RHFSelect> */}
    </>
  );
}
