// @mui
import { Stack, Button, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
// assets
import { DocIllustration } from '../../../assets';
import { dispatch } from '../../../redux/store'
import {activarEstado } from '../../../redux/slices/user'

// ----------------------------------------------------------------------

const cambiarestado = async () => {

  console.log("se envia algo")
  try{
    dispatch(activarEstado({"estado":1}));
   
  }catch (error){
    console.log(error)
  }
  // try {
  //   const newMemo = {
  //     documento:'memorandum',
  //     typo: data.typo,
  //     dep_remitente: data.Remitente,
  //     destino: data.destino,
  //     asunto: data.name,
  //     contenido: data.description,
  //     distribucion: data.Distribucion,
  //     adjuntos:data.images,
  //     actividad:data.actividad
  //   };
  //   // if (event.id) {
  //   //   dispatch(updateEvent(event.id, newEvent));
  //   //   enqueueSnackbar('Update success!');
  //   // } else {
  //   //   enqueueSnackbar('Create success!');
  //   //   dispatch(createEvent(newEvent));
  //   // }
    
    
  //   enqueueSnackbar('Creacion exitosa!');
  //   navigate(PATH_DASHBOARD.eCommerce.list);
  //   dispatch(createMemo(newMemo));
  //   reset();
  // } catch (error) {
  //   console.error(error);
  // }
};

export default function NavbarDocs() {
  const { user } = useAuth();
  
  
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}
    >
      <DocIllustration sx={{ width: 1 }} />

      <div>
        <Typography gutterBottom variant="subtitle1">
          Hola, {user?.nombre}...
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Le informamos que usted se encuentra actualmente desactivado en el sistema
        </Typography>
      </div>

      <Button href='/dashboard/app' onClick={cambiarestado} variant="contained">
        Activar
      </Button>
    </Stack>
  );
}
