import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 180, height: 80, ...sx }}>
      <svg xmlns="" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(-70.000000,400.000000) scale(0.023100000,-0.023100000)"
fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
<path d="M4691 14648 l4 -313 -308 -19 c-169 -11 -309 -17 -312 -14 -2 2 -16
141 -31 309 -15 167 -27 305 -28 306 -2 2 -202 -18 -521 -53 -242 -26 -415
-52 -427 -63 -4 -4 9 -140 29 -303 21 -163 34 -300 30 -304 -4 -4 -93 -22
-199 -40 -106 -17 -242 -41 -301 -52 -78 -14 -110 -17 -112 -8 -3 6 -27 130
-55 276 -28 145 -57 278 -65 296 l-13 32 -184 -38 c-176 -37 -608 -136 -696
-159 -30 -8 -42 -17 -42 -30 0 -10 31 -142 70 -294 38 -152 70 -283 70 -291 0
-16 -33 -27 -398 -135 -95 -28 -183 -51 -196 -51 -25 0 -36 29 -141 399 -30
107 -57 196 -60 200 -9 8 -702 -228 -745 -254 -9 -6 -1 -30 75 -235 26 -69 59
-159 75 -200 73 -196 163 -435 165 -437 1 -2 85 28 186 65 915 340 1945 596
2879 716 365 47 615 70 1040 96 259 16 1206 13 1471 -5 1000 -66 1843 -205
2749 -454 280 -76 869 -268 1135 -369 72 -27 131 -48 133 -47 3 2 60 153 157
410 130 348 166 449 161 461 -6 15 -737 258 -747 247 -4 -4 -14 -33 -22 -63
-35 -130 -151 -526 -156 -532 -4 -4 -51 5 -106 21 -217 62 -490 143 -497 147
-15 10 -7 53 57 314 36 145 65 274 65 286 0 25 9 22 -175 68 -250 62 -688 156
-731 156 -26 0 -28 -6 -94 -344 -48 -242 -52 -256 -80 -256 -19 0 -220 34
-469 79 -56 11 -107 23 -112 28 -6 6 5 124 28 306 21 163 34 299 31 303 -10 9
-310 48 -618 78 -146 15 -279 29 -296 31 -27 5 -33 2 -37 -17 -3 -13 -16 -149
-28 -303 -12 -154 -26 -284 -30 -289 -7 -8 -519 18 -596 30 l-31 5 5 303 6
303 -129 6 c-71 4 -288 7 -483 7 l-353 0 3 -312z"/>
<path d="M4640 14013 c-507 -22 -1235 -99 -1690 -179 -80 -14 -170 -30 -201
-35 -253 -42 -732 -154 -1171 -275 -349 -96 -1015 -322 -1185 -403 -96 -46
-118 -76 -111 -151 8 -78 62 -130 135 -130 39 0 126 29 363 120 1178 452 2514
723 3845 780 1671 71 3355 -191 4875 -760 91 -34 218 -82 282 -106 65 -24 129
-44 143 -44 34 0 73 21 110 60 26 27 30 38 30 88 -1 74 -20 102 -98 140 -132
65 -700 262 -1052 366 -897 263 -1678 407 -2725 501 -254 23 -1254 41 -1550
28z"/>
<path d="M4795 13704 c-137 -4 -629 -35 -633 -39 -4 -4 26 -407 31 -412 2 -2
48 1 102 7 287 30 1284 33 1670 5 99 -7 182 -11 184 -9 5 4 30 300 31 360 l0
51 -97 7 c-329 22 -436 26 -788 30 -214 2 -439 2 -500 0z"/>
<path d="M3945 13653 c-103 -9 -532 -68 -730 -99 -396 -63 -965 -180 -988
-203 -8 -8 40 -243 74 -363 13 -48 22 -49 205 -4 373 91 924 186 1379 236 105
11 205 23 223 26 l32 5 0 60 c0 65 -18 289 -26 327 -4 20 -10 22 -67 20 -34
-1 -80 -3 -102 -5z"/>
<path d="M6235 13650 c-10 -15 -41 -388 -33 -395 3 -3 86 -14 184 -25 483 -53
1011 -143 1444 -245 220 -52 199 -56 226 50 32 124 69 313 64 319 -7 6 -386
89 -595 130 -258 50 -521 91 -836 131 -360 45 -444 51 -454 35z"/>
<path d="M2100 13329 c-19 -5 -102 -28 -185 -51 -361 -98 -858 -259 -1180
-383 -104 -40 -198 -75 -207 -78 -16 -6 -15 -13 13 -89 82 -224 125 -328 136
-328 7 0 77 25 155 56 401 158 823 301 1173 399 72 20 160 45 196 56 l66 19
-18 82 c-10 46 -31 137 -47 203 -32 131 -34 133 -102 114z"/>
<path d="M8142 13207 c-43 -180 -61 -277 -51 -283 5 -2 66 -21 136 -40 452
-124 968 -300 1401 -479 23 -10 44 -16 47 -14 2 3 20 48 39 100 19 51 52 143
74 202 l40 109 -56 25 c-89 39 -456 174 -676 247 -305 102 -680 211 -897 260
-26 6 -26 5 -57 -127z"/>
<path d="M4785 13240 c-353 -9 -695 -36 -1090 -85 -164 -21 -369 -55 -376 -63
-8 -8 -1 -62 41 -345 17 -108 30 -199 30 -202 0 -10 72 -4 285 24 375 51 822
86 1228 97 l247 7 -2 286 -3 286 -30 1 c-16 1 -165 -2 -330 -6z"/>
<path d="M5190 12962 l0 -289 258 -7 c405 -11 842 -45 1227 -97 120 -16 233
-29 250 -29 l31 0 37 263 c21 144 37 269 37 278 0 12 -16 19 -57 28 -128 24
-674 86 -983 111 -99 8 -642 29 -772 30 l-28 0 0 -288z"/>
<path d="M3205 13083 c-55 -7 -378 -71 -520 -103 -387 -85 -768 -189 -1158
-317 l-139 -45 7 -36 c20 -108 142 -492 156 -492 14 0 82 19 284 80 440 134
838 230 1323 320 116 22 175 37 179 46 8 22 -68 538 -81 546 -6 4 -13 7 -16 6
-3 0 -18 -3 -35 -5z"/>
<path d="M7076 13063 c-2 -10 -12 -67 -20 -128 -29 -200 -56 -397 -56 -400 0
-8 49 -19 220 -50 447 -81 867 -184 1331 -326 107 -33 208 -61 224 -62 l29 -2
72 225 c95 297 92 282 52 298 -342 134 -993 309 -1573 423 -227 44 -271 48
-279 22z"/>
<path d="M4735 12613 c-1066 -35 -2238 -253 -3240 -602 -388 -135 -567 -204
-640 -247 -113 -66 -112 -226 1 -300 61 -41 113 -30 439 91 1048 389 2269 634
3369 675 546 21 1074 10 1509 -30 1024 -95 1967 -305 2882 -640 240 -88 338
-120 370 -120 45 0 118 50 139 95 25 51 18 140 -13 183 -21 31 -113 82 -206
115 -22 8 -96 36 -165 62 -468 178 -962 326 -1497 450 -131 30 -491 102 -578
115 -33 5 -148 22 -255 39 -319 49 -773 94 -1125 111 -185 9 -766 11 -990 3z"/>
<path d="M1270 12575 c-19 -8 -118 -45 -220 -84 -308 -116 -352 -134 -357
-146 -2 -7 28 -97 67 -201 40 -104 81 -215 92 -246 11 -32 24 -58 29 -58 9 0
212 74 487 178 l123 46 -6 36 c-8 43 -96 332 -129 420 -13 36 -30 66 -37 67
-8 1 -30 -4 -49 -12z"/>
<path d="M8982 12503 c-66 -188 -134 -425 -125 -434 5 -5 41 -21 79 -35 38
-14 110 -41 159 -59 50 -18 152 -56 228 -85 79 -30 142 -49 147 -44 15 16 182
485 177 497 -3 7 -25 20 -48 29 -24 9 -120 47 -214 83 -171 67 -355 135 -366
135 -4 0 -20 -39 -37 -87z"/>
<path d="M22 7448 l3 -3483 28 -125 c130 -590 345 -1097 669 -1579 460 -684
1127 -1263 1913 -1661 633 -320 1245 -497 2000 -577 205 -22 844 -25 1040 -5
743 76 1382 256 1998 563 513 256 927 550 1320 938 279 275 434 458 616 726
353 522 572 1045 691 1655 l30 155 0 3438 0 3437 -5155 0 -5156 0 3 -3482z
m9648 3156 c14 -10 44 -35 67 -57 l42 -40 61 56 c63 58 90 68 132 46 11 -6 26
-26 34 -46 18 -41 11 -55 -62 -134 l-43 -46 54 -56 c58 -60 68 -93 44 -140
-16 -30 -28 -37 -67 -37 -27 0 -45 11 -93 57 l-59 56 -59 -56 c-46 -44 -66
-57 -91 -57 -45 0 -80 38 -80 87 0 34 7 46 55 93 l54 54 -54 57 c-68 71 -74
111 -24 153 36 30 55 32 89 10z m-8883 -27 c42 -42 35 -73 -34 -147 l-56 -61
50 -52 c67 -69 78 -90 61 -124 -19 -38 -62 -67 -90 -59 -12 3 -50 30 -83 60
l-60 54 -57 -54 c-63 -60 -102 -70 -147 -35 -43 33 -34 79 28 151 53 61 53 61
35 83 -11 12 -24 25 -30 29 -6 4 -23 24 -37 45 -23 33 -25 43 -17 72 21 75 87
82 158 19 26 -24 51 -49 56 -57 6 -10 19 -3 55 31 87 85 120 93 168 45z m4594
4 c16 -16 29 -40 29 -54 0 -28 -43 -91 -88 -129 l-32 -27 60 -59 c69 -69 77
-102 36 -148 -18 -21 -32 -28 -60 -28 -31 0 -45 9 -94 57 l-57 56 -57 -54
c-49 -48 -61 -55 -96 -55 -32 0 -45 6 -61 26 -37 47 -26 80 47 156 l45 47 -56
61 c-66 70 -73 105 -32 145 42 43 84 35 153 -30 l59 -54 43 44 c42 43 92 74
118 75 8 0 27 -13 43 -29z m3354 -760 l369 -6 -107 -72 c-59 -40 -157 -107
-219 -149 -62 -43 -162 -111 -223 -152 -60 -42 -150 -103 -200 -137 -49 -34
-142 -97 -205 -140 -63 -43 -163 -111 -222 -152 -220 -149 -325 -221 -418
-284 -52 -36 -151 -104 -220 -151 -207 -141 -757 -515 -890 -606 -69 -47 -174
-119 -235 -160 -60 -42 -157 -108 -215 -147 -58 -39 -148 -101 -200 -137 -100
-68 -406 -276 -500 -340 l-55 -37 -6 67 c-4 37 -7 640 -8 1340 l-1 1272 1593
-1 c875 -1 1758 -5 1962 -8z m485 -126 c0 -52 0 -98 0 -102 0 -5 -43 -21 -97
-36 -255 -73 -428 -202 -603 -448 -38 -52 -89 -119 -114 -149 -133 -155 -304
-251 -544 -305 -147 -32 -257 -73 -353 -131 -94 -56 -226 -176 -284 -258 -171
-241 -240 -317 -352 -390 -112 -72 -195 -105 -353 -141 -198 -45 -313 -93
-435 -180 -83 -59 -201 -185 -273 -291 -160 -237 -329 -369 -554 -431 -51 -14
-61 -15 -69 -2 -10 14 -8 214 1 222 3 2 282 193 620 424 338 231 673 459 744
508 70 48 196 134 280 191 138 94 262 178 701 477 180 123 468 319 805 550 63
43 178 121 255 173 77 53 246 167 375 254 129 88 238 160 243 160 4 0 7 -43 7
-95z m4 -212 c-1 -7 -2 -44 -2 -82 l-2 -68 -90 -21 c-213 -50 -422 -194 -563
-387 -152 -210 -193 -258 -266 -317 -113 -91 -242 -149 -449 -203 -190 -49
-264 -77 -362 -140 -128 -82 -222 -178 -328 -335 -67 -100 -172 -213 -255
-275 -103 -78 -205 -121 -402 -170 -338 -84 -504 -196 -690 -465 -83 -120
-208 -255 -286 -308 -89 -60 -219 -116 -316 -137 l-33 -7 0 85 0 85 73 17
c229 52 452 215 608 445 35 52 98 130 139 174 158 166 293 235 589 301 100 22
206 64 294 116 96 58 264 227 347 349 122 180 298 329 458 387 37 14 122 38
190 54 346 83 512 198 711 493 131 194 290 319 499 392 98 35 138 39 136 17z
m-2 -314 l3 -81 -29 -5 c-71 -14 -250 -83 -318 -122 -119 -68 -254 -195 -334
-314 -66 -97 -145 -196 -201 -249 -113 -107 -266 -187 -423 -223 -381 -86
-533 -182 -739 -470 -47 -66 -124 -159 -171 -206 -138 -139 -245 -197 -455
-248 -173 -42 -244 -66 -340 -114 -163 -81 -275 -185 -408 -377 -88 -127 -170
-217 -253 -276 -88 -63 -243 -133 -347 -158 l-28 -7 3 83 3 83 110 36 c117 37
208 80 284 131 74 50 201 185 281 298 41 58 109 140 150 184 144 151 261 215
509 276 197 49 244 66 353 132 161 97 235 170 357 350 29 43 84 111 122 152
139 148 273 226 484 277 232 57 325 94 450 178 80 54 193 168 252 253 193 280
320 387 558 469 124 43 123 44 127 -52z m-2 -248 l0 -79 -32 -10 c-18 -6 -59
-18 -91 -27 -217 -60 -412 -205 -559 -416 -211 -301 -337 -390 -668 -474 -274
-69 -403 -136 -550 -284 -42 -42 -109 -125 -150 -186 -185 -270 -330 -372
-645 -450 -190 -48 -314 -96 -415 -165 -91 -61 -216 -189 -288 -297 -160 -239
-294 -347 -527 -429 -110 -38 -117 -34 -113 62 l3 77 80 22 c242 64 444 213
599 440 197 287 352 391 702 469 285 63 484 205 664 471 131 193 291 328 467
392 37 13 124 37 193 53 326 74 478 181 710 500 116 159 224 260 350 325 57
30 213 84 243 85 l27 0 0 -79z m0 -246 c0 -84 0 -85 -27 -90 -69 -14 -224 -70
-283 -103 -176 -96 -279 -194 -405 -383 -119 -178 -255 -296 -422 -367 -40
-17 -139 -47 -220 -67 -355 -87 -497 -188 -728 -514 -73 -103 -187 -220 -260
-266 -86 -56 -205 -103 -337 -136 -225 -55 -325 -95 -450 -183 -84 -58 -202
-183 -268 -282 -140 -209 -261 -317 -439 -394 -110 -47 -179 -66 -192 -53 -5
5 -9 43 -7 83 l3 74 65 16 c256 65 434 195 614 450 188 267 347 378 643 450
174 42 247 66 323 108 159 86 275 196 403 382 127 184 271 309 434 375 37 15
136 44 218 65 178 44 281 85 384 153 95 63 219 188 290 292 140 206 263 319
437 402 59 28 177 67 217 72 4 1 7 -37 7 -84z m-5 -175 c7 -12 10 -148 3 -155
-3 -3 -47 -17 -97 -30 -249 -67 -426 -198 -601 -446 -102 -144 -143 -191 -230
-261 -110 -90 -222 -139 -434 -193 -190 -49 -286 -87 -382 -153 -88 -59 -186
-153 -245 -235 -166 -227 -190 -255 -301 -348 -113 -94 -211 -137 -422 -185
-140 -32 -271 -83 -366 -142 -95 -58 -249 -212 -320 -317 -163 -243 -293 -347
-539 -431 l-96 -32 -3 56 c-2 32 -1 71 3 88 5 29 10 32 86 52 100 27 208 77
299 138 91 61 224 201 302 317 34 51 80 113 102 139 66 75 182 166 264 207 91
45 133 59 313 105 318 80 484 194 664 456 102 149 243 290 346 346 90 50 129
64 320 114 80 21 182 53 227 70 171 66 332 203 457 390 153 230 336 372 570
441 73 21 72 21 80 9z m2 -375 l3 -130 -103 -68 c-56 -37 -194 -131 -307 -208
-113 -77 -254 -174 -315 -215 -142 -97 -377 -257 -535 -365 -355 -243 -644
-440 -855 -583 -77 -52 -219 -149 -315 -215 -96 -66 -238 -162 -315 -215 -77
-52 -192 -130 -255 -173 -219 -149 -405 -277 -480 -328 -88 -61 -314 -215
-453 -309 -54 -36 -100 -66 -103 -66 -2 0 -4 55 -4 123 l0 122 83 23 c100 28
251 99 332 158 115 82 183 157 293 319 156 231 344 358 621 420 352 80 534
203 726 492 112 168 254 292 414 363 41 17 135 47 210 66 180 46 222 60 311
105 155 77 248 162 393 359 154 209 253 302 395 374 64 32 221 86 242 83 12
-2 15 -26 17 -132z m-9 -995 c-9 -8 -66 -49 -127 -90 -61 -41 -153 -104 -206
-140 -52 -36 -144 -99 -205 -140 -61 -41 -153 -104 -205 -140 -52 -36 -144
-99 -205 -140 -61 -41 -534 -364 -1053 -718 -518 -354 -1012 -691 -1097 -748
-266 -180 -479 -325 -645 -439 -88 -60 -185 -126 -215 -146 l-55 -37 -6 27
c-9 34 -11 669 -3 710 7 33 -1 27 469 346 72 48 177 120 235 160 58 39 157
107 220 150 63 43 156 106 205 140 50 34 205 140 345 235 140 95 296 201 345
235 50 34 142 97 205 140 63 43 162 111 220 150 58 39 161 110 230 157 69 47
179 122 245 167 66 46 183 125 260 177 77 53 232 158 345 235 237 162 370 253
565 385 l140 95 5 -378 c5 -343 3 -379 -12 -393z m-6845 468 c86 -87 431 -737
630 -1188 91 -206 146 -320 154 -320 4 0 23 35 42 78 178 401 498 1053 624
1267 49 83 114 171 141 188 37 25 71 21 95 -9 33 -42 28 -104 -23 -246 -55
-155 -204 -530 -216 -542 -4 -4 -37 4 -73 18 -87 35 -98 32 -19 -4 34 -16 65
-36 69 -45 3 -9 -1 -32 -10 -51 l-17 -34 -36 25 c-20 14 -46 25 -58 25 -13 0
-6 -7 24 -22 42 -21 45 -26 44 -61 -1 -21 -24 -88 -54 -157 l-52 -119 -52 28
c-56 32 -112 48 -101 31 3 -5 13 -10 21 -10 15 0 89 -35 111 -52 9 -7 7 -20
-7 -51 -21 -45 -35 -49 -77 -21 -13 8 -44 16 -70 19 -47 4 -44 3 28 -16 20 -5
48 -18 62 -29 l26 -21 -21 -43 -21 -44 -45 24 c-25 13 -54 24 -66 23 -12 0 2
-9 32 -20 29 -12 56 -28 59 -36 8 -19 -7 -61 -67 -190 l-48 -102 -59 24 c-74
31 -115 31 -181 0 -30 -14 -67 -25 -83 -25 -32 0 -80 -19 -116 -46 -21 -15
-23 -14 -58 56 -55 110 -115 251 -115 272 0 13 15 25 48 39 81 35 91 40 57 33
-16 -3 -52 -16 -80 -30 l-50 -23 -12 32 c-24 67 -11 85 87 111 42 11 48 15 25
15 -16 1 -54 -10 -83 -24 -30 -14 -56 -25 -58 -25 -8 0 -34 60 -34 79 0 22 65
61 124 75 15 4 26 9 23 13 -9 8 -103 -26 -132 -49 -14 -11 -27 -19 -29 -17
-17 19 -126 273 -126 292 0 13 82 60 125 71 11 3 2 3 -20 0 -22 -3 -57 -16
-77 -30 l-38 -24 -15 30 c-29 55 -14 76 73 105 28 9 52 19 52 22 0 9 -66 -10
-112 -33 -33 -17 -47 -20 -51 -11 -3 7 -35 86 -72 177 -179 440 -206 540 -170
610 13 25 22 30 51 30 27 0 43 -9 77 -42z m-89 -306 c14 -37 26 -73 26 -80 0
-8 -16 -12 -45 -12 -54 0 -115 -28 -143 -67 -18 -24 -23 -26 -39 -15 -82 57
-111 64 -161 38 -62 -32 -112 -116 -112 -187 0 -37 -14 -37 -29 -1 -27 68 -43
156 -36 208 3 30 10 56 14 58 4 3 47 10 94 16 105 13 258 52 335 85 31 13 60
24 64 25 3 0 18 -30 32 -68z m1817 49 c74 -33 212 -69 326 -86 63 -9 114 -22
123 -31 28 -28 4 -248 -29 -268 -5 -4 -18 25 -30 63 -42 138 -160 191 -252
113 l-31 -26 -27 32 c-31 38 -86 62 -141 62 -25 0 -40 5 -40 13 0 19 47 147
53 147 3 0 24 -9 48 -19z m-47 -349 c29 -8 48 -8 77 2 l38 13 16 -53 c27 -93
105 -154 198 -154 44 0 31 -15 -44 -50 -133 -61 -268 -52 -343 25 -52 53 -116
131 -116 142 0 4 14 43 31 85 l31 77 36 -38 c22 -23 53 -43 76 -49z m-1654 4
l32 -75 -43 -57 c-74 -96 -113 -130 -162 -143 -85 -21 -181 -10 -266 32 -56
28 -64 47 -19 47 82 1 171 79 184 161 l6 38 62 -6 c70 -6 112 9 143 52 10 13
21 25 24 25 4 0 21 -33 39 -74z m6830 -59 l0 -102 -119 -38 c-223 -71 -397
-204 -550 -420 -210 -298 -332 -383 -670 -468 -183 -45 -238 -66 -346 -130
-138 -81 -242 -183 -350 -344 -173 -257 -361 -385 -660 -450 -325 -71 -516
-205 -724 -512 -121 -179 -280 -301 -479 -369 -148 -52 -142 -55 -142 74 0 91
3 111 18 124 9 9 103 73 207 143 222 150 487 329 635 430 58 39 184 125 280
190 164 111 244 165 545 370 226 154 540 368 615 420 41 28 259 177 485 331
225 153 456 311 512 349 57 39 174 118 260 177 87 59 230 157 318 217 88 61
161 110 163 111 1 0 2 -46 2 -103z m-5 -197 c3 -5 6 -42 7 -82 l1 -72 -104
-31 c-171 -51 -295 -124 -419 -247 -57 -56 -87 -94 -212 -268 -52 -72 -148
-169 -215 -216 -97 -70 -213 -118 -391 -163 -90 -22 -197 -55 -238 -72 -170
-71 -308 -190 -451 -390 -213 -298 -341 -385 -688 -465 -289 -67 -481 -196
-655 -440 -153 -217 -250 -309 -405 -387 -82 -41 -233 -90 -252 -83 -10 4 -13
25 -11 83 l3 78 81 20 c258 66 437 197 625 460 197 274 333 367 659 449 340
85 490 186 690 466 110 153 169 220 243 276 111 83 235 135 452 188 123 30
236 74 329 129 98 57 253 215 340 345 83 123 219 258 326 321 65 39 235 109
267 111 6 0 15 -4 18 -10z m0 -325 l0 -79 -50 -14 c-198 -54 -377 -158 -495
-289 -28 -32 -88 -110 -132 -173 -182 -259 -358 -379 -643 -441 -339 -74 -514
-199 -744 -528 -73 -105 -164 -194 -267 -261 -91 -60 -171 -91 -326 -130 -242
-61 -333 -99 -466 -200 -99 -74 -159 -140 -263 -286 -164 -232 -324 -355 -545
-419 -47 -14 -90 -22 -95 -19 -5 3 -9 40 -9 83 l0 79 68 16 c153 38 267 92
393 187 89 68 158 144 259 289 160 230 350 366 587 421 394 92 545 187 745
469 152 215 279 330 443 399 39 16 134 45 210 65 168 41 221 59 300 98 176 88
274 181 440 418 105 151 228 259 370 326 86 41 189 75 210 70 6 -2 10 -37 10
-81z m7 -237 c2 -46 -1 -86 -5 -89 -5 -3 -41 -14 -80 -23 -87 -21 -212 -78
-298 -137 -87 -59 -213 -187 -284 -289 -97 -141 -166 -220 -244 -282 -122 -97
-227 -143 -466 -202 -154 -38 -239 -73 -350 -144 -91 -58 -225 -194 -298 -304
-122 -181 -223 -281 -364 -359 -89 -49 -152 -71 -301 -104 -151 -34 -295 -92
-398 -160 -97 -65 -217 -189 -296 -306 -153 -227 -307 -350 -529 -423 -59 -20
-113 -36 -119 -36 -6 0 -10 33 -10 88 l0 87 80 17 c238 49 446 195 610 428
143 203 249 306 391 380 87 45 147 65 334 110 72 18 160 45 197 60 164 70 317
205 455 400 95 136 165 215 242 274 106 82 261 146 448 185 282 60 491 209
667 473 96 145 218 261 348 333 56 30 235 103 260 105 3 0 8 -37 10 -82z
m-8812 22 c19 -16 46 -40 59 -54 l24 -26 57 54 c30 29 66 56 78 60 49 16 111
-45 99 -96 -4 -15 -30 -51 -58 -82 l-51 -56 56 -61 c63 -67 70 -98 35 -143
-16 -20 -29 -26 -60 -26 -35 0 -48 7 -97 54 l-57 54 -58 -54 c-58 -54 -88 -65
-132 -48 -24 10 -48 62 -40 91 3 14 29 49 57 79 l51 54 -57 60 c-31 34 -56 70
-56 80 0 35 19 69 47 84 33 18 56 13 103 -24z m9568 7 c15 -14 22 -32 22 -55
0 -30 -10 -45 -58 -94 l-59 -58 59 -58 c48 -49 58 -64 58 -94 0 -44 -37 -81
-80 -80 -30 1 -81 35 -129 86 l-24 25 -59 -54 c-46 -43 -66 -55 -91 -55 -42 0
-74 23 -82 59 -8 38 5 64 63 124 l46 47 -46 48 c-58 59 -71 85 -63 123 8 36
40 59 82 59 25 0 45 -12 90 -54 l58 -54 54 50 c80 73 110 80 159 35z m-757
-232 c0 -14 1 -49 1 -77 1 -60 20 -48 -160 -106 -207 -68 -375 -204 -532 -432
-181 -263 -331 -367 -635 -440 -347 -83 -505 -187 -696 -457 -198 -281 -363
-398 -651 -463 -165 -37 -214 -53 -303 -98 -167 -83 -292 -198 -428 -397 -149
-216 -326 -350 -544 -410 -89 -25 -94 -21 -91 72 l3 77 70 17 c162 40 308 114
427 218 77 66 116 112 210 244 175 247 348 372 599 432 263 63 361 103 495
203 92 68 156 139 282 312 181 250 326 350 602 414 155 36 229 61 331 112 148
74 282 196 387 354 97 147 187 243 298 318 94 63 249 130 307 131 22 1 27 -3
28 -24z m-5897 -69 c36 -15 74 -61 85 -104 6 -22 13 -27 37 -24 16 1 57 5 92
8 81 8 139 -13 185 -67 27 -31 41 -39 67 -39 22 0 43 -10 67 -30 18 -16 41
-30 50 -30 9 0 26 -8 37 -19 12 -10 46 -26 76 -34 37 -11 67 -28 90 -52 34
-35 35 -39 35 -113 0 -42 -3 -86 -8 -98 -7 -21 -6 -21 43 -11 91 18 182 -28
216 -110 19 -44 18 -137 -2 -178 l-16 -34 -41 22 c-57 30 -156 34 -249 12
-121 -30 -418 -192 -418 -228 0 -21 67 -230 91 -282 58 -130 112 -185 203
-205 179 -40 332 124 281 300 -30 104 -30 104 -7 91 12 -6 38 -45 58 -86 35
-71 36 -77 32 -157 -5 -97 -27 -146 -86 -200 -52 -47 -100 -62 -178 -55 l-64
5 0 -47 c0 -90 -65 -182 -138 -197 -46 -9 -125 31 -178 89 l-45 50 -27 -26
c-56 -54 -147 -72 -216 -43 -33 14 -37 13 -91 -21 -96 -60 -196 -78 -290 -52
-33 9 -105 51 -161 93 -8 7 -25 -2 -55 -27 -50 -43 -104 -61 -157 -51 -40 7
-88 28 -113 50 -14 13 -24 10 -74 -21 -92 -55 -184 -48 -253 18 -39 37 -67 87
-76 137 -4 26 -19 46 -49 71 -23 20 -56 60 -72 90 -26 48 -30 64 -30 135 0 75
3 87 38 153 20 40 37 76 37 81 0 5 -17 18 -37 30 -66 38 -112 114 -112 185 -1
69 68 162 144 196 l40 18 -45 0 c-119 2 -222 78 -222 164 0 91 61 150 173 168
67 11 78 16 114 54 22 23 56 50 74 60 38 19 113 24 173 12 36 -8 38 -6 52 28
16 37 62 65 107 65 11 0 34 11 51 25 34 29 101 34 132 10 26 -20 36 -19 36 2
0 9 14 32 32 50 27 27 39 33 76 33 24 0 52 -5 62 -10 17 -9 22 -5 39 27 28 55
51 72 117 84 32 6 74 19 94 29 42 23 127 26 174 6z m5896 -236 l0 -79 -42 -11
c-191 -48 -367 -146 -488 -271 -30 -31 -97 -115 -149 -188 -54 -75 -130 -165
-176 -210 -138 -132 -255 -189 -509 -247 -289 -66 -479 -198 -665 -462 -123
-175 -208 -260 -336 -335 -89 -52 -160 -78 -338 -123 -180 -45 -271 -81 -372
-149 -97 -64 -228 -198 -309 -315 -159 -229 -306 -347 -522 -419 -138 -46
-135 -47 -132 51 l3 83 102 27 c105 28 221 81 309 140 75 50 214 194 274 283
197 295 362 408 712 484 111 24 154 39 236 80 171 86 285 190 423 383 115 162
210 258 318 321 106 63 172 88 328 126 176 43 300 93 403 162 116 78 191 154
291 296 136 194 223 280 367 360 78 44 205 92 245 92 l27 1 0 -80z m0 -297
l-1 -138 -137 -92 c-75 -50 -191 -129 -257 -174 -66 -45 -190 -129 -275 -188
-85 -58 -202 -138 -260 -177 -58 -40 -195 -134 -305 -209 -110 -75 -294 -201
-410 -280 -115 -79 -273 -186 -350 -239 -77 -52 -232 -158 -345 -235 -113 -77
-250 -170 -305 -208 -55 -37 -264 -180 -465 -317 -201 -137 -427 -291 -502
-342 -76 -52 -201 -137 -277 -189 -77 -52 -142 -95 -145 -95 -3 0 -6 58 -6
130 0 126 1 130 23 134 144 30 233 64 357 136 91 53 243 203 315 310 129 196
249 309 410 388 72 35 131 55 250 83 208 48 313 90 430 174 73 52 195 178 255
264 141 201 206 269 324 345 110 71 181 99 349 140 226 55 296 84 433 182 91
65 166 145 257 274 140 200 278 324 437 395 67 30 166 64 188 64 9 1 12 -33
12 -136z m0 -777 l1 -537 -41 -125 c-253 -777 -749 -1454 -1445 -1975 -495
-369 -1135 -652 -1750 -773 -234 -46 -419 -66 -722 -79 l-83 -4 1 621 c1 342
4 634 8 650 5 24 29 45 126 111 66 44 212 144 325 220 290 198 513 349 675
459 77 52 208 140 290 197 179 122 1374 937 1595 1088 297 203 456 310 595
405 77 52 203 137 280 190 76 52 140 94 142 92 2 -1 3 -244 3 -540z m-4888
386 c50 -31 92 -96 91 -138 -2 -70 -68 -124 -174 -144 -29 -6 -54 -11 -55 -13
-2 -2 8 -25 21 -52 18 -35 25 -65 25 -107 0 -137 -88 -227 -223 -228 -154 0
-226 96 -321 428 l-15 52 47 35 c98 71 313 172 402 189 59 11 167 -1 202 -22z
m-3251 -2322 c6 0 35 -23 64 -51 l55 -51 56 51 c30 28 67 54 81 57 51 13 111
-42 99 -90 -3 -13 -28 -49 -56 -79 -27 -30 -50 -59 -50 -64 0 -5 12 -20 26
-34 40 -36 84 -100 84 -120 0 -9 -12 -31 -26 -48 -42 -50 -79 -44 -154 25
l-60 57 -59 -57 c-33 -31 -69 -56 -80 -56 -33 0 -68 20 -80 47 -21 46 -13 70
44 129 l55 57 -55 59 c-57 61 -65 85 -44 132 12 26 54 48 77 40 8 -2 18 -4 23
-4z m7870 -57 l56 -55 20 26 c36 45 104 96 130 96 33 0 83 -51 83 -85 0 -19
-17 -45 -60 -89 l-59 -62 55 -55 c61 -61 72 -86 54 -130 -15 -35 -37 -49 -79
-49 -22 0 -45 14 -91 57 l-62 57 -31 -37 c-40 -47 -85 -77 -115 -77 -31 0 -82
52 -82 84 0 16 19 45 55 84 30 33 55 63 55 66 0 4 -25 32 -56 62 -53 51 -56
57 -51 91 10 58 49 88 102 75 11 -2 45 -29 76 -59z m-3838 -1933 c30 -27 58
-50 62 -50 5 0 32 23 61 50 43 41 59 50 89 50 43 0 85 -36 85 -73 0 -26 -40
-86 -88 -131 l-30 -28 59 -58 c43 -42 59 -65 59 -85 0 -39 -18 -65 -56 -81
-43 -18 -69 -6 -133 60 l-44 45 -59 -54 c-69 -65 -111 -73 -153 -30 -42 41
-34 77 32 146 l56 59 -56 59 c-65 68 -73 101 -32 145 40 43 82 36 148 -24z"/>
<path d="M3495 5576 c-37 -16 -82 -68 -91 -103 -17 -65 25 -193 77 -238 19
-17 19 -15 -5 16 -32 44 -66 136 -66 180 0 78 60 139 138 139 57 0 95 -21 123
-67 12 -21 24 -40 25 -42 2 -2 14 6 28 16 14 11 45 26 69 33 39 10 50 10 81
-5 70 -34 107 -106 86 -170 -17 -51 -48 -85 -171 -181 l-109 -85 -61 48 c-90
70 -111 82 -54 28 53 -49 103 -85 120 -85 6 0 28 15 50 34 22 18 76 62 120 97
122 98 150 168 102 254 -24 43 -91 85 -137 85 -14 0 -43 -9 -65 -20 -22 -11
-43 -20 -47 -20 -5 0 -23 16 -40 36 -55 62 -109 78 -173 50z"/>
<path d="M2725 5570 c-27 -10 -95 -86 -95 -105 0 -14 -3 -14 -30 0 -16 8 -52
15 -80 15 -45 0 -55 -4 -90 -37 -52 -49 -62 -107 -32 -176 26 -59 83 -92 252
-148 69 -23 130 -44 136 -46 25 -9 144 287 144 359 0 98 -114 175 -205 138z
m114 -20 c47 -24 81 -75 81 -123 0 -56 -115 -337 -138 -337 -13 0 -234 76
-283 98 -53 23 -85 59 -99 113 -31 116 73 202 187 154 19 -8 38 -15 42 -15 4
0 17 20 29 45 14 30 34 51 59 64 48 26 74 26 122 1z"/>
<path d="M3141 5425 c-33 -28 -76 -128 -111 -258 -25 -94 -25 -119 -2 -139 24
-22 96 -57 161 -79 85 -30 95 -21 11 10 -92 34 -135 56 -160 81 -24 24 -21 45
33 210 42 131 81 190 125 190 26 0 66 -38 90 -86 24 -48 53 -223 55 -335 l2
-84 -35 -6 -35 -6 41 -2 c22 0 43 1 45 3 8 8 -9 200 -26 298 -20 112 -48 182
-85 208 -38 27 -72 25 -109 -5z"/>
<path d="M2233 4940 c-84 -51 -93 -186 -15 -250 l34 -28 -26 -55 c-25 -50 -26
-59 -16 -98 28 -103 149 -146 243 -84 30 19 112 139 173 253 l39 74 -35 30
c-52 44 -207 148 -246 164 -47 20 -113 18 -151 -6z m169 -21 c84 -43 238 -158
238 -177 0 -14 -102 -187 -142 -240 -49 -65 -90 -92 -144 -92 -116 0 -173 134
-95 223 l29 32 -40 25 c-49 31 -68 65 -68 124 0 34 6 52 27 77 52 61 113 70
195 28z"/>
<path d="M2845 4533 c-100 -133 -135 -200 -135 -261 0 -75 51 -140 130 -163
51 -15 112 6 160 56 33 34 34 34 47 15 21 -30 87 -60 129 -60 55 0 118 41 143
93 26 54 26 83 2 147 -41 107 -174 214 -337 270 l-51 18 -88 -115z m189 60
c154 -64 269 -177 282 -277 8 -58 -10 -108 -52 -146 -61 -53 -163 -38 -216 32
l-17 23 -35 -41 c-43 -51 -83 -68 -138 -61 -108 14 -161 126 -112 234 10 24
57 95 104 158 94 127 80 121 184 78z"/>
<path d="M22605 9150 c-445 -45 -857 -172 -1211 -375 -668 -381 -1186 -1029
-1390 -1738 -87 -301 -117 -533 -118 -887 0 -355 25 -572 104 -877 200 -771
745 -1446 1459 -1807 430 -217 854 -318 1401 -333 920 -25 1760 258 2488 838
l102 82 0 1268 0 1269 -1265 0 -1265 0 0 -555 0 -555 645 0 645 0 0 -415 0
-415 -47 -35 c-72 -53 -314 -170 -433 -209 -129 -43 -316 -81 -474 -97 -150
-15 -480 -7 -596 15 -386 72 -687 237 -945 519 -325 356 -478 773 -478 1302 1
341 58 596 197 872 95 190 193 321 370 498 191 191 377 309 601 384 382 127
857 101 1296 -70 210 -82 493 -246 668 -389 52 -43 66 -50 76 -38 7 7 189 226
405 487 l393 475 -29 23 c-515 424 -1089 664 -1804 753 -112 15 -680 21 -795
10z"/>
<path d="M13715 9144 c-127 -8 -325 -36 -432 -60 -651 -146 -1165 -571 -1348
-1116 -145 -434 -116 -988 72 -1362 236 -470 674 -724 1746 -1012 639 -172
904 -276 1047 -410 105 -99 150 -201 150 -341 0 -296 -175 -472 -535 -539
-122 -23 -450 -26 -590 -6 -462 66 -926 274 -1348 605 -54 42 -102 77 -107 77
-5 0 -179 -203 -385 -450 l-376 -451 23 -20 c587 -504 1192 -779 1939 -881
222 -30 762 -33 959 -5 781 111 1325 484 1570 1077 83 201 123 429 123 700 0
208 -13 316 -58 486 -66 252 -231 516 -421 677 -272 230 -712 430 -1293 588
-624 170 -840 238 -1017 324 -170 82 -260 182 -299 330 -17 67 -19 179 -4 244
27 118 113 227 236 300 147 86 299 116 558 108 253 -8 447 -51 717 -158 191
-76 498 -242 677 -366 35 -24 67 -41 71 -36 4 4 155 222 335 483 l327 474 -28
24 c-73 59 -239 174 -356 244 -416 249 -862 397 -1367 453 -137 15 -477 26
-586 19z"/>
<path d="M17400 6145 l0 -2915 640 0 640 0 0 2915 0 2915 -640 0 -640 0 0
-2915z"/>
<path d="M26680 6145 l0 -2915 2220 0 2220 0 0 570 0 570 -1580 0 -1580 0 0
615 0 615 1375 0 1375 0 -2 568 -3 567 -1372 3 -1373 2 0 590 0 590 1560 0
1560 0 0 570 0 570 -2200 0 -2200 0 0 -2915z"/>
<path d="M32260 6145 l0 -2915 630 0 630 0 2 1886 3 1887 806 -1224 c682
-1035 810 -1224 829 -1224 19 0 149 191 839 1239 l816 1239 3 -1902 2 -1901
635 0 635 0 0 2915 0 2915 -692 0 -693 -1 -764 -1230 -763 -1230 -22 33 c-12
18 -357 572 -766 1231 l-744 1197 -693 0 -693 0 0 -2915z"/>
</g>

        {/* <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g> */}
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
