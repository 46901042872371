import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, CircularProgress, Autocomplete } from '@mui/material';

// ----------------------------------------------------------------------

RHFAsyn.propTypes = {
  name: PropTypes.string,
  onOpen6: PropTypes.func,
  onClose6: PropTypes.func,
  open: PropTypes.bool,
  label: PropTypes.any,
  options: PropTypes.array,
  loading: PropTypes.bool

};

export default function RHFAsyn({ name, open, label, options, loading, onOpen6, onClose6, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          {...other}
          onChange={(event, newValue) => field.onChange(newValue)}
          
          // sx={{ width: 300 }}
          open={open}
          onOpen={() => {
            onOpen6();
          }}
          onClose={() => {
            onClose6();
          }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
          getOptionLabel={(option) => `${option.dep_name||option.departamento} - ${option.titulo||''} - ${option.activo?.nombre.split(" ", 1)||''} ${option.activo?.apePaterno||''} `}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
