import { formatDuration, formatDistance, intervalToDuration, parseISO, format, getTime, formatDistanceToNow, differenceInMinutes, differenceInHours, differenceInSeconds , daysToWeeks, getDay, getWeek, getYear, addWeeks, startOfWeek, addDays } from 'date-fns';
import {es} from 'date-fns/esm/locale'


// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy',{locale :es} );
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm',{
    locale : es
  });
}

export function fDateTimeFull(date) {
  return format(new Date(date), 'dd MMMM yyyy HH:mm',{
    locale : es
  });
}

export function fDateTimeHours(date) {
  return format(new Date(date), 'HH:mm',{
    locale : es
  });
}

export function fDateTimeSimple(date) {
  return format(new Date(date), 'dd - MM - yyyy',{
    locale : es
  });
}

export function fDateTimeSimpleReves(date) {
  return format(new Date(date), 'yyyy.MM.dd',{
    locale : es
  });
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}


export function fDateTimeSuffix(date) {
  
  return format(new Date(date), 'dd/MM/yyyy hh:mm p', {locale :es});
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale:es
  });
}

export function fDaysToWeek(date){
  return getWeek(date);
}
 
export function fAddWeek(date, weeks){
  return addWeeks(date, weeks); 
}


export function fDaysInWeek (date, weekNumber){
  const startDate = startOfWeek(new Date(getYear(date), 0, 1)); // Obtiene el inicio de la semana del 1 de enero de ese año
  const daysToAdd = (weekNumber - 1) * 7; // Calcula los días a añadir basados en el número de semana
  const days = [];

  for (let i = 0; i < 7; i+=1) {
    const date = addDays(startDate, daysToAdd + i +1);
    days.push(format(date, 'dd - MMMM', {locale :es})); // Formatea la fecha como 'yyyy-MM-dd'
  }

  return days;
}

export function fDateAddDays (date, days) {
  const newDate = addDays(date, days)

  return newDate


}

export function fDateFull (date){
  const day= ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", ]
  const dayOfTheWeek = getDay(new Date(date))
  console.log("dotw", getDay(new Date(date)))
  const fechaCompleta = `${day[dayOfTheWeek]} ${format(new Date(date), 'dd MMMM yyyy',{locale :es})}`
  return fechaCompleta
}

export function fDifferenceInHours (date1, date2) {
  const diferenciaMIn = differenceInMinutes(parseISO(date1), parseISO(date2) )
  const diferenciaHoras = differenceInHours(parseISO(date1), parseISO(date2), {roundingMethod:"trunc"} )
  const diferenciaSecon = differenceInSeconds(parseISO(date1), parseISO(date2) )
  // return intervalToDuration({start: date1, end: date2}) 
  return `${diferenciaHoras} horas, ${diferenciaMIn - (diferenciaHoras*60) } minutos, ${diferenciaSecon- (60*diferenciaMIn)} segundos `
}

export function fDistance (seconds){

 // Conviertes los segundos a milisegundos
  const durationInMilliseconds = seconds * 1000;

  // Usamos `intervalToDuration` para obtener la duración en unidades más grandes
  const duration = intervalToDuration({ start: 0, end: durationInMilliseconds });

  // Formateamos la duración con `formatDuration`
  const formattedDuration = formatDuration(duration, {
    format:['days','hours','minutes'],
    locale:es
  });

  return formattedDuration
}