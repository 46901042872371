import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node
};

const useCurrentRole = () => {
  const { user} = useAuth();
  console.log("usuarios",user)
  // Logic here to get current user role
  const role = user.roles.map((rol) => rol.name); 
  return role;
};

function algunElementoIncluido(arr1, arr2) {
  return arr1.some(item => arr2.includes(item));
}

export default function RoleBasedGuard({ accessibleRoles, children }) {
  const currentRole = useCurrentRole();

  if (!algunElementoIncluido(accessibleRoles, currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permiso Denegado</AlertTitle>
            No tienes permiso para acceder a esta página
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
