import axios from '../../utils/axios';
//
import { dispatch } from '../store';
// ----------------------------------------------------------------------
export function activarEstado(newEstado) {
    return async () => {
      // dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('api/users/estado/activar', newEstado);
        console.log(response)
      } catch (error) {
        dispatch((error));
      }
    };
}

export function desactivarEstado(estado){
    
    return async() =>{
        try{
            console.log('jsadjhsajdadsad')
            const response= await axios.post('api/users/estado/auto', estado)
            console.log(response)
        }catch (error){
            dispatch((error));
        }
    }

}