// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function CheckOutIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 200 200">
        
        
        <path
          fill="#FBCDBE"
          d="M80.767 107.443a5.346 5.346 0 01-4.374-9.672l10.879-13.266 9.663 1.73-13.016 13.69a5.316 5.316 0 01-3.152 7.518zM154.914 165.818l6.739-.106-.811 22.969h-5.927l-.001-22.863z"
        />
        
        <path fill="#FBCDBE" d="M110.295 159.726l7.362 4.726-14.726 17.716-4.988-3.203 12.352-19.239z" />
        <path
          fill={PRIMARY_DARK}
          d="M97.717 176.52l9.823 6.307h.001a7.44 7.44 0 012.24 10.279l-.13.203-16.084-10.325 4.15-6.464z"
        />
        <path
          fill={PRIMARY_DARKER}
          d="M107.673 89.59s16.356-54.326 21.613-55.06c5.257-.734 13.726 13.94 13.726 13.94 10.981 14.01 22.304 59.393 22.304 59.393s-18.876 1.181-32.674 8.523c-13.799 7.341-30.083-.372-30.083-.372l5.114-26.424z"
        />
        <path
          fill={PRIMARY_DARK}
          d="M121.092 73.336v27.298l-3.213 39.544-16.05 29.912 7.66 5.471 21.887-28.452 8.657-28.252 13.595 62.541 10.213-.73-7.093-86.712-13.461-24.407-22.195 3.787z"
        />
        <path
          fill={PRIMARY_LIGHT}
          d="M137.583 46.933c5.107 5.107.365 10.578.365 10.578l5.837 13.132-22.981 6.202-8.032-35.887 5.472-5.836c10.943-.365 10.949-.592 10.949-.592 1.918 1.877 8.39 12.403 8.39 12.403z"
        />
        <path
          fill={PRIMARY_DARKER}
          d="M111.72 37.334a9.803 9.803 0 00-2.837 12.522c2.633 5.026 5.465 12.811 4.005 21.358-2.643 15.468-11.114 27.22-15.832 30.993-1.643 1.314-1.7 3.227-1.46 4.601.674 3.838 4.395 8.192 6.969 9.066.173.057.355.086.537.084 4.181.001 14.466-13.315 26.284-39.327 7.361-16.2 2.019-29.19-3.759-37.234a9.805 9.805 0 00-13.78-2.157l-.127.094z"
        />
        <path
          fill={PRIMARY_DARKER}
          d="M122.621 52.996L89.432 96.543l-8.754-3.648 31.371-52.893 10.572 12.994zM132.466 29.802h-22.827a1.773 1.773 0 01-1.771-1.77v-9.84a13.186 13.186 0 018.137-12.188 13.185 13.185 0 0118.232 12.188v9.84a1.77 1.77 0 01-1.771 1.77z"
        />
        <path fill="#FBCDBE" d="M132.244 24.543a9.667 9.667 0 00-16.964-9.273 9.667 9.667 0 1016.964 9.273z" />
        <path
          fill={PRIMARY_DARKER}
          d="M137.356 19.176h-13.958l-.143-2.004-.716 2.004h-2.149l-.284-3.972-1.418 3.972h-4.159v-.197a10.442 10.442 0 0110.43-10.43h1.968a10.44 10.44 0 0110.429 10.43v.197z"
        />
        <path
          fill={PRIMARY_DARKER}
          d="M123.284 31.64c-.105-.001-.21-.01-.313-.029l-10.221-1.803V12.916h11.251l-.279.325c-3.875 4.52-.955 11.849 1.13 15.815a1.747 1.747 0 01-.139 1.853 1.756 1.756 0 01-1.429.73z"
        />
      </svg>
    </Box>
  );
}
