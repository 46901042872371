// @mui
import { styled } from '@mui/material/styles';
import { Card, Alert, Container, Typography } from '@mui/material';
// routes
// hooks
// import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  backgroundImage: `url('/logo/banner2.png')`, 
  backgroundSize: 'cover' 
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 580,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {

  // const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Iniciar Sesión">
      <RootStyle>

        {/* {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, ml:4, mb:5 }}>
              Bienvenido a SIGEM
              <Typography sx={{ color: 'text.secondary' }}>Sistema gestor de documentos Municipal</Typography>
            </Typography>
            <Image
              alt="login"
              src="/logo/EscudoVerticalSinLetras.png"
            />
          </SectionStyle>
        )} */}

        <Container maxWidth="sm">
          <ContentStyle>
            <Card sx={[{"padding":10}]}>
            <Typography variant="h3" textAlign={'center'} sx={{   mb:5 }}>
              Bienvenido a SIGEM
              <Typography sx={{ color: 'text.secondary' }}>Sistema gestor de documentos Municipal</Typography>
            </Typography>
              <Alert severity="info" sx={{ mb: 3 }}>
                usuario: <strong>jperez</strong> / Contraseña:<strong> 17854</strong>
              </Alert>
              <LoginForm />

            </Card>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
