import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import {  DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {es} from 'date-fns/locale'


// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
          <DatePicker
            {...field}
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />}
          />
          {/* <TextField {...field} fullWidth error={!!error} helperText={error?.message} {...other} /> */}
        </LocalizationProvider>

      )}
    />
  );
}

