import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock';
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import axios from '../../../utils/axios';

import { PATH_DASHBOARD } from '../../../routes/paths';



// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(null);

  const { user } = useAuth();
 
  const handleClick = (noti, id, isRead) =>{
    if (!isRead.includes(id)) axios.post('api/notificacion/', {id})
    const label = noti.doc === "Memo" ? 'inbox':'id_revision'
    handleClose()
    if (noti.doc==="fichaC") {
      navigate(`${PATH_DASHBOARD.contratacionAdmin.root}/list/${noti.id}`, {state:{from:'admin'}});
    }else if (noti.doc==="fichaD"){
      navigate(`${PATH_DASHBOARD.contratacionAdmin.root}/list/${noti.id}`);
    }else {
      navigate(`${PATH_DASHBOARD.mail.root}/${label}/${noti.id}`);

    }

    console.log("hola mundo", noti)
  }
  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get('/api/notificacion/')
      setNotifications(response.data)
      console.log("lunes de qwerty", response.data);
    }
    fetchData();
  }, [open])

  console.log(" Notificaciones",_notifications)
  


  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  
  
 
  const totalUnRead= notifications.filter((item) => !item.isRead.includes(user._id)).length;
  

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notificaciones</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Tienes {totalUnRead} nostificaciones sin revisar
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            // subheader={
            //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            //     New
            //   </ListSubheader>
            // }
          >
            {notifications.map((notification) => (
              <NotificationItem key={notification._id} notification={notification}
                                id={user._id} handleClick={(noti)=> handleClick(noti, notification._id, notification.isRead)} />
            ))}
          </List>

          {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            Ver todos
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.object,
    avatar: PropTypes.any,
    isRead: PropTypes.array,
    createdAt: PropTypes.any,
  }),
  id: PropTypes.string,
  handleClick: PropTypes.func
};

function NotificationItem({ notification, id, handleClick }) {
  const { avatar, title } = renderContent(notification);
  console.log("se encuentra leido", notification.isRead.includes(id))
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!notification.isRead.includes(id) && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={()=>handleClick(notification.type)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  console.log("memo5", notification.type.doc)
  const title = (
    <Typography variant="subtitle2">
      {notification.titulo}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.message}
      </Typography>
    </Typography>
  );
  if (notification.type.doc === 'fichaC') {
    console.log("memo2")
    return {
      avatar: (
        <img
          alt={notification.title}
          src={`/icons/signaturef.svg`} 
          sx={{ width: 1, height: 1 }}
        />
      ),
      title,
    };
  }

  if (notification.type.doc === 'Memof') {
    console.log("memo2")
    return {
      avatar: (
        <img
          alt={notification.title}
          src={`/icons/signature.svg`} 
          sx={{ width: 1, height: 1 }}
        />
      ),
      title,
    };
  }
  if (notification.type.doc === 'Memo') {
    console.log("memo3")
    return {
      avatar: (
        <img
          alt={notification.title}
          src="/icons/document.svg"
          sx={{ width: 1, height: 1 }}
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimadsa/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://miniasdsadp/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
